import _ from 'lodash';
import TypeSelect from './components/select/index.vue';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { getProductPriceTitle, resolveHoverProductImage, resolveProductImage, routeToProduct } from '@/helpers';

export default {
  name: 'ProductCard',
  components: {
    TypeSelect
  },
  props: {
    product: {
      type: Object,
      default: () => {}
    },
    hover: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      isProductPriceInBasket: false,
      selectedProductPrice: null,
      favoriteSelect: false,
      basketKey: 1,
      leftoverType: null
    };
  },
  computed: {
    ...mapGetters({
      isAuthenticated: 'auth/isAuthenticated',
      favourites: 'favorites/whichList',
      basket: 'basket/basket',
      user: 'profile/user',
      showNotifyLoader: 'products/showNotifyLoader'
    })
  },
  watch: {
    selectedProductPrice: function () {
      this.$router.query = this.selectedProductPrice.value;
      this.selectProductPrice(this.selectedProductPrice);
    },
    basket: function () {
      this.basketKey += 1;
      this.checkProductInBasket();
    }
  },
  created() {
    if (this.product.productPrices) {
      let sorted = this.product.productPrices?.data.sort(this.compare);
      this.selectProductPrice(sorted[1] ? sorted[1] : sorted[0]);
    }
  },
  methods: {
    getProductPriceTitle,
    ...mapMutations({
      addToBasket: `basket/ADD_ITEM_TO_BASKET`,
      changeLoginPopup: 'popups/CHANGE_LOGIN_POPUP',
      changeFastBuyPopup: `popups/CHANGE_FAST_BUY_POPUP`,
      changeBasketPopup: 'basket/SHOW_BASKET_POPUP'
    }),
    ...mapActions({
      addToFavorite: 'favorites/ADD_PRODUCT_TO_FAVOURITES',
      removeFromFavorite: 'favorites/REMOVE_PRODUCT_IN_FAVORITE',
      getFavorites: 'favorites/GET_FAVORITES_LIST',
      showNotifyPopup: 'products/SHOW_POPUP_PRODUCT_WHEN_AVAILABLE'
    }),
    routeToProduct: routeToProduct,
    resolveProductImage: resolveProductImage,
    resolveHoverProductImage: resolveHoverProductImage,
    /**
     * @param {ProductPrice} productPrice
     */
    selectProductPrice(productPrice) {
      this.selectedProductPrice = productPrice;
      this.leftoverType = productPrice.leftoverType.data;
      this.checkProductInBasket();
    },
    compare(a, b) {
      // Use toUpperCase() to ignore character casing
      const bandA = a.value;
      const bandB = b.value;

      let comparison = 0;
      if (bandA > bandB) {
        comparison = 1;
      } else if (bandA < bandB) {
        comparison = -1;
      }
      return comparison;
    },
    openFastBuy() {
      this.changeFastBuyPopup({
        show: true,
        product: {
          select_count: 1,
          select_type: this.selectedProductPrice,
          ...this.product
        }
      });
    },
    checkProductInBasket() {
      this.basketKey += 2;
      this.isProductPriceInBasket = _.some(this.basket, { select_type: { id: this.selectedProductPrice.id } });
    },
    addBasket() {
      if (this.product.hasDuplicate) {
        this.$toasted.info('Цей товар переміщено до новї серії та не можливо купи');
        return;
      }

      this.addToBasket({
        select_count: 1,
        product_price: this.selectedProductPrice,
        product: this.product,
        unauthorizedProductPriceId: this.product.unauthorizedProductPriceId
      });
      fbq('track', 'AddToCart', {
        content_type: 'product',
        currency: 'UAH',
        value: this.selectedProductPrice.price,
        content_name: this.product.title,
        content_ids: this.product.unauthorizedProductPriceId,
        content_category: this.product.brandSeries?.title || this.product.brand?.title || this.product.title
      });
      this.changeBasketPopup(true);
      this.checkProductInBasket();
      // this.$toasted.success(this.$t('successAddBasket'));
    },
    showNotify() {
      const payload = {
        product_id: this.product.id,
        product_price_id: this.selectedProductPrice.id
      };

      this.showNotifyPopup(payload);
    },
    changeFavorite() {
      if (this.isAuthenticated) {
        const index = this.favourites.find((e) => e.productId === this.product.id);
        if (index) {
          this.removeFromFavorite(index.id).then(() => {
            this.getFavorites();
            this.favoriteSelect = false;
            this.$toasted.success(this.$t('successRemoveFromFavorite'));
          });
        } else {
          this.addToFavorite({ product_id: this.product.id }).then(() => {
            this.favoriteSelect = true;
            this.$toasted.success(this.$t('successAddToFavorites'));
            this.getFavorites();
          });
        }
      } else {
        this.changeLoginPopup(true);
      }
    }
  }
};
